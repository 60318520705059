
import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import languages from './languages.json'
import { auth } from '../firebase'
import router from '../router/index'
import jwt from 'jsonwebtoken'
Vue.use(Vuex)
export default new Vuex.Store({
    state: {
        auth: {
            isAuth: false,
            user: null,
            token: null
        },

        init_state: false,
        user: {},

        languages: languages,
        language: languages[localStorage.getItem('lang') || 0],

        employees_list: [],

        extra_hours_list: [],

        file_attachments_list: [],

        fines_list: [],

        iligal_days_list: [],

        iligal_hours_list: [],

        insurances_list: [],

        off_day_requests_list: [],

        off_days_list: [],

        off_hour_request_list: [],

        off_hours_list: [],

        positions_list: [],

        profile_attachments_list: [],

        users_list: [],

        working_hours_list: [],
        commissions_list: [],

        salary_list: [],

        salary_list_list: [],
        salary_report_list: [],

        incurrences_take_outs_list: [],




    },
    getters: {
        language: state => state.language,
        languages: state => state.languages,
        auth: state => state.auth,
        user: state => state.user,

        employees_list: state => state.employees_list,

        extra_hours_list: state => state.extra_hours_list,

        file_attachments_list: state => state.file_attachments_list,

        fines_list: state => state.fines_list,

        iligal_days_list: state => state.iligal_days_list,

        iligal_hours_list: state => state.iligal_hours_list,

        insurances_list: state => state.insurances_list,

        off_day_requests_list: state => state.off_day_requests_list,

        off_days_list: state => state.off_days_list,

        off_hour_request_list: state => state.off_hour_request_list,

        off_hours_list: state => state.off_hours_list,

        positions_list: state => state.positions_list,

        profile_attachments_list: state => state.profile_attachments_list,

        users_list: state => state.users_list,

        working_hours_list: state => state.working_hours_list,

        commissions_list: state => state.commissions_list,
        salary_list: state => state.salary_list,

        salary_list_list: state => state.salary_list_list,
        salary_report_list: state => state.salary_report_list,

        incurrences_take_outs_list: state => state.incurrences_take_outs_list,

    },
    mutations: {
        setLanguage(state, language) {
            state.language = language
        },

        setAuth(state, payload) {
            state.auth.user = payload.user
            state.auth.isAuth = true
            state.auth.token = payload.token
            localStorage.setItem('auth', JSON.stringify(auth))
            // localStorage.setItem('role', payload.user.user_role)
        },
        setEmployeesList(state, employees_list) {
            state.employees_list = employees_list
        },

        setExtraHoursList(state, extra_hours_list) {
            state.extra_hours_list = extra_hours_list
        },

        setFileAttachmentsList(state, file_attachments_list) {
            state.file_attachments_list = file_attachments_list
        },

        setFinesList(state, fines_list) {
            state.fines_list = fines_list
        },

        setIligalDaysList(state, iligal_days_list) {
            state.iligal_days_list = iligal_days_list
        },

        setIligalHoursList(state, iligal_hours_list) {
            state.iligal_hours_list = iligal_hours_list
        },

        setInsurancesList(state, insurances_list) {
            state.insurances_list = insurances_list
        },

        setOffDayRequestsList(state, off_day_requests_list) {
            state.off_day_requests_list = off_day_requests_list
        },

        setOffDaysList(state, off_days_list) {
            state.off_days_list = off_days_list
        },

        setOffHourRequestList(state, off_hour_request_list) {
            state.off_hour_request_list = off_hour_request_list
        },

        setOffHoursList(state, off_hours_list) {
            state.off_hours_list = off_hours_list
        },

        setPositionsList(state, positions_list) {
            state.positions_list = positions_list
        },

        setProfileAttachmentsList(state, profile_attachments_list) {
            state.profile_attachments_list = profile_attachments_list
        },

        setUsersList(state, users_list) {
            state.users_list = users_list
        },

        setWorkingHoursList(state, working_hours_list) {
            state.working_hours_list = working_hours_list
        },


        setCommissionsList(state, commissions_list) {
            state.commissions_list = commissions_list
        },
        setSalaryList(state, salary_list) {
            state.salary_list = salary_list
        },

        setSalaryListList(state, salary_list_list) {
            state.salary_list_list = salary_list_list
        },
        setSalaryReportList(state, salary_report_list) {
            state.salary_report_list = salary_report_list
        },
        setIncurrencesTakeOutsList(state, incurrences_take_outs_list) {
            state.incurrences_take_outs_list = incurrences_take_outs_list
        },

    },
    actions: {
        init(context, uid) {
            const id = uid
            // const id = 'yn5lsqU1MwZPOPfSAUR7tmQ8be82'
            axios.get('init/' + id)
                .then(r => {
                    if (r.status == 200 && r.data) {
                        // console.log(r.data)
                        // console.log(r.data.token)
                        // console.log("+++++++++========================")
                        context.commit('setAuth', {
                            user: r.data.user,
                            token: r.data.token

                        })
                        axios.defaults.headers.common['Authorization'] = `Bearer ${r.data.token}`;
                        // context.commit('setUsersList', r.data.users)
                        // context.commit('setCustomersList', r.data.customers)
                        context.commit('setUsersList', r.data.users)
                        context.commit('setSalaryReportList', r.data.salary_report)
                        // context.commit('setOrdersList', r.data.orders)
                        // context.commit('setStoreInformation', r.data.store_information)
                        // context.commit('setItemColorsList', r.data.item_colors)
                        // context.commit('setItemTypesList', r.data.item_types)
                        // context.commit('setItemSizesList', r.data.item_sizes)
                        // context.commit('setShopItemsList', r.data.shop_items)
                        // context.commit('setDriversList', r.data.drivers)
                        context.state.user = r.data.user
                        context.state.init_state = true
                    } else {

                    }
                })
                .catch(error => {
                    console.log(error)
                })
        },
        logout(context) {
            auth.signOut()
            localStorage.removeItem('auth')
            context.state.auth.isAuth = false
            router.push('/login')
            context.state.init_state = true
        },
    },
})
