import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

// Get the value of 'lang' from localStorage
const lang = localStorage.getItem('lang');

// Determine the direction based on the value of 'lang'
const isRTL = lang === '1';

export default new Vuetify({
    rtl: isRTL, // Set the application direction dynamically based on 'lang' value

    theme: {
        themes: {
            light: {
                primary: '#B30000',
            },
            dark: {
                primary: '#B30000',
            },
        },
    },
});
