
<template>
	<div>
		<v-app-bar app class="navbar">
			<v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>
			<v-btn icon="" to="/">
				<v-icon>mdi-home</v-icon>
			</v-btn>
			<v-app-bar-title>{{$store.getters.language.app_name}}</v-app-bar-title>
            <v-spacer></v-spacer>
					<v-menu transition="slide-y-transition" offset-y open-on-hover class="hidden-sm-and-down">
						<template v-slot:activator="{ on, attrs }">
							<v-btn v-bind="attrs" v-on="on" class="mx-1" text dark>
								English
								<v-icon size="15" right>mdi-arrow-down</v-icon>
							</v-btn>
						</template>
						<v-list>
							<v-list-item v-for="(item, index) in languages" :key="index" @click="setLanguage(item , index)">
								<v-list-item-title>{{item.name}}</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
			<v-btn icon class="mx-1" dark @click="$vuetify.theme.dark = !$vuetify.theme.dark">
				<v-icon>mdi-theme-light-dark</v-icon>
			</v-btn>
			<v-btn icon="" @click="logout">

				<v-icon>mdi-logout</v-icon>
			</v-btn>
		</v-app-bar>
		<v-navigation-drawer v-model="drawer" app :right="$store.getters.language.rtl">
			<template v-slot:prepend>
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="text-center">{{$store.getters.language.app_name}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</template>
            
			<v-divider class="mt-3"></v-divider>
			<v-list shaped v-if="user.user_role=='hr'">
				<v-list-item v-for="(item , index) in navigation_links" :key="index" :to="item.path">
					<v-list-item-content>
						<v-list-item-title>{{item.title}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list shaped v-if="user.user_role=='viewer'">
				<v-list-item v-for="(item , index) in navigation_links_viewer" :key="index" :to="item.path">
					<v-list-item-content>
						<v-list-item-title>{{item.title}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
			<v-list shaped v-if="user.user_role=='supervisor'">
				<v-list-item v-for="(item , index) in navigation_links_supervisor" :key="index" :to="item.path">
					<v-list-item-content>
						<v-list-item-title>{{item.title}}</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
			</v-list>
            <v-list shaped v-if="user.user_role=='ceo'">
                <v-list-item v-for="(item , index) in navigation_links_ceo" :key="index" :to="item.path">
                    <v-list-item-content>
                        <v-list-item-title>{{item.title}}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
		</v-navigation-drawer>
	</div>
</template>
<script>
	export default {
		data: function () {
			return {
				drawer: null,
			}
		},

		computed: {
			languages() {
				return this.$store.getters.languages
			},
			user() {
				return this.$store.getters.auth.user
			},
			navigation_links() {
				return this.$store.getters.language.data.nav.navigation_link_home

				
			},
			navigation_links_supervisor() {

				return this.$store.getters.language.data.nav.navigation_links_supervisor
			},
            navigation_links_ceo() {
                return [
                    // {
                    //     path: '/off_day_requests-list',
                    //     title: 'Off Day Requests',
                    // },
                    {
                        path: '/off_day_requests-view',
                        title: 'Off Day Requests View',
                    },
                      {
                        path : '/salary_list-list',
                        title : 'Salary List',
                    },
                    // {
                    //     path: '/my_account',
                    //     title: 'My Account',
                    // }
                ]
            },

			navigation_links_viewer() {
				return this.$store.getters.language.data.nav.navigation_links_viewer
			},
		},
		methods: {
          		setLanguage(lang, index) {
				this.$store.commit('setLanguage', lang)
				this.$vuetify.rtl = lang.rtl
				localStorage.setItem('lang', index)
				window.location.reload()
			},
			logout() {
				this.$store.dispatch('logout');
			},

		},
	}
</script>
        