import { initializeApp } from "firebase/app";
import "firebase/auth";
import { getAuth, signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";

const firebaseConfig = {
    apiKey: "AIzaSyASo3fcwdtlbdGeTxR6u3fJ_s6MEFV5MEo",
    authDomain: "topit-d841c.firebaseapp.com",
    projectId: "topit-d841c",
    storageBucket: "topit-d841c.appspot.com",
    messagingSenderId: "826485977408",
    appId: "1:826485977408:web:3500ee7a0b9ee26da64aa5",
    measurementId: "G-HPKGKRRYQJ"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app)

export { auth, app, signInWithEmailAndPassword, sendPasswordResetEmail }
