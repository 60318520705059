
import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)
const routes = [
    {
        path: '/',
        name: 'Home',
        component: () => import('./../views/Home.vue'),
        meta: {
            auth: false,
            title: 'Home'
        }
    },
    {
        path: '/login',
        name: 'Login',
        component: () => import('./../views/Login.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },
    {
        path: '/reset-password',
        name: 'ResetPassword',
        component: () => import('./../views/ResetPassword.vue'),
        meta: {
            auth: false,
            title: 'Login'
        }
    },

    {
        path: '/employees-list',
        name: 'ListEmployees',
        component: () => import('./../views/Employees/List.vue'),
        meta: {
            auth: true,
            title: 'Employees List'
        }
    },
    {
        path: '/employees-view',
        name: 'ViewEmployees',
        component: () => import('./../views/Employees/View.vue'),
        meta: {
            auth: true,
            title: 'Employees View'
        }
    },
    {
        path: '/deactive-employees-view',
        name: 'DeactiveViewEmployees',
        component: () => import('./../views/Employees/ViewDea.vue'),
        meta: {
            auth: true,
            title: 'Deactivated Employees View'
        }
    },
    {
        path: '/employees-list/:id',
        name: 'ViewEmployees',
        component: () => import('./../views/Employees/Edit.vue'),
        meta: {
            auth: true,
            title: 'Employees Edit'
        }
    },
    {
        path: '/extra_hours-list',
        name: 'ListExtra_hours',
        component: () => import('./../views/Extra_hours/List.vue'),
        meta: {
            auth: true,
            title: 'Extra_hours List'
        }
    },
    {
        path: '/extra_hours-list/:id',
        name: 'ViewExtra_hours',
        component: () => import('./../views/Extra_hours/Edit.vue'),
        meta: {
            auth: true,
            title: 'Extra_hours Edit'
        }
    },
    {
        path: '/file_attachments-list/:id',
        name: 'ListFile_attachments',
        component: () => import('./../views/File_attachments/List.vue'),
        meta: {
            auth: true,
            title: 'File_attachments List'
        }
    },
    {
        path: '/commissions-list',
        name: 'ListCommissions',
        component: () => import('./../views/Commissions/List.vue'),
        meta: {
            auth: true,
            title: 'Commissions List'
        }
    },
    {
        path: '/commissions-list/:id',
        name: 'ViewCommissions',
        component: () => import('./../views/Commissions/Edit.vue'),
        meta: {
            auth: true,
            title: 'Commissions Edit'
        }
    },
    {
        path: '/salary_add-list/:id',
        name: 'ListSalary',
        component: () => import('./../views/Salary/List.vue'),
        meta: {
            auth: true,
            title: 'Salary List'
        }
    },
    {
        path: '/salary_account',
        name: 'AccountSalary',
        component: () => import('./../views/Salary/Account.vue'),
        meta: {
            auth: true,
            title: 'Salary Account'
        }
    },
    {
        path: '/salary_add-view/:id',
        name: 'ViewSalary',
        component: () => import('./../views/Salary_list/View.vue'),
        meta: {
            auth: true,
            title: 'Salary View'
        }
    },
    {
        path: '/salary-list/:id',
        name: 'ViewSalary',
        component: () => import('./../views/Salary/Edit.vue'),
        meta: {
            auth: true,
            title: 'Salary Edit'
        }
    },
    {
        path: '/salary_list-list',
        name: 'ListSalary_list',
        component: () => import('./../views/Salary_list/List.vue'),
        meta: {
            auth: true,
            title: 'Salary_list List'
        }
    },
    {
        path: '/salary_list-list/:id',
        name: 'ViewSalary_list',
        component: () => import('./../views/Salary_list/Edit.vue'),
        meta: {
            auth: true,
            title: 'Salary_list Edit'
        }
    },
    {
        path: '/extra_days-list',
        name: 'ListExtra_days',
        component: () => import('./../views/Extra_days/List.vue'),
        meta: {
            auth: true,
            title: 'Extra_days List'
        }
    },
    {
        path: '/extra_days-list/:id',
        name: 'ViewExtra_days',
        component: () => import('./../views/Extra_days/Edit.vue'),
        meta: {
            auth: true,
            title: 'Extra_days Edit'
        }
    },
    // {
    //     path: '/file_attachments-list/:id',
    //     name: 'ViewFile_attachments',
    //     component: () => import('./../views/File_attachments/Edit.vue'),
    //     meta: {
    //         auth: true,
    //         title: 'File_attachments Edit'
    //     }
    // },
    {
        path: '/fines-list',
        name: 'ListFines',
        component: () => import('./../views/Fines/List.vue'),
        meta: {
            auth: true,
            title: 'Fines List'
        }
    },
    {
        path: '/fines-list/:id',
        name: 'ViewFines',
        component: () => import('./../views/Fines/Edit.vue'),
        meta: {
            auth: true,
            title: 'Fines Edit'
        }
    },
    {
        path: '/iligal_days-list',
        name: 'ListIligal_days',
        component: () => import('./../views/Iligal_days/List.vue'),
        meta: {
            auth: true,
            title: 'Iligal_days List'
        }
    },
    {
        path: '/iligal_days-list/:id',
        name: 'ViewIligal_days',
        component: () => import('./../views/Iligal_days/Edit.vue'),
        meta: {
            auth: true,
            title: 'Iligal_days Edit'
        }
    },
    {
        path: '/iligal_hours-list',
        name: 'ListIligal_hours',
        component: () => import('./../views/Iligal_hours/List.vue'),
        meta: {
            auth: true,
            title: 'Iligal_hours List'
        }
    },
    {
        path: '/iligal_hours-list/:id',
        name: 'ViewIligal_hours',
        component: () => import('./../views/Iligal_hours/Edit.vue'),
        meta: {
            auth: true,
            title: 'Iligal_hours Edit'
        }
    },
    {
        path: '/insurances-list',
        name: 'ListInsurances',
        component: () => import('./../views/Insurances/List.vue'),
        meta: {
            auth: true,
            title: 'Insurances List'
        }
    },
    {
        path: '/insurances-list/:id',
        name: 'ViewInsurances',
        component: () => import('./../views/Insurances/Edit.vue'),
        meta: {
            auth: true,
            title: 'Insurances Edit'
        }
    },
    {
        path: '/off_day_requests-list',
        name: 'ListOff_day_requests',
        component: () => import('./../views/Off_day_requests/List.vue'),
        meta: {
            auth: true,
            title: 'Off_day_requests List'
        }
    },
    {
        path: '/off_day_requests-view',
        name: 'ViewOff_day_requests',
        component: () => import('./../views/Off_day_requests/View.vue'),
        meta: {
            auth: true,
            title: 'Off_day_requests View'
        }
    },
    {
        path: '/off_day_requests-list/:id',
        name: 'ViewOff_day_requests',
        component: () => import('./../views/Off_day_requests/Edit.vue'),
        meta: {
            auth: true,
            title: 'Off_day_requests Edit'
        }
    },
    {
        path: '/off_days-list',
        name: 'ListOff_days',
        component: () => import('./../views/Off_days/List.vue'),
        meta: {
            auth: true,
            title: 'Off_days List'
        }
    },
    {
        path: '/off_days-list/:id',
        name: 'ViewOff_days',
        component: () => import('./../views/Off_days/Edit.vue'),
        meta: {
            auth: true,
            title: 'Off_days Edit'
        }
    },
    {
        path: '/off_hour_request-list',
        name: 'ListOff_hour_request',
        component: () => import('./../views/Off_hour_request/List.vue'),
        meta: {
            auth: true,
            title: 'Off_hour_request List'
        }
    },
    {
        path: '/off_hour_request-list/:id',
        name: 'ViewOff_hour_request',
        component: () => import('./../views/Off_hour_request/Edit.vue'),
        meta: {
            auth: true,
            title: 'Off_hour_request Edit'
        }
    },
    {
        path: '/off_hours-list',
        name: 'ListOff_hours',
        component: () => import('./../views/Off_hours/List.vue'),
        meta: {
            auth: true,
            title: 'Off_hours List'
        }
    },
    {
        path: '/off_hours-list/:id',
        name: 'ViewOff_hours',
        component: () => import('./../views/Off_hours/Edit.vue'),
        meta: {
            auth: true,
            title: 'Off_hours Edit'
        }
    },
    {
        path: '/positions-list',
        name: 'ListPositions',
        component: () => import('./../views/Positions/List.vue'),
        meta: {
            auth: true,
            title: 'Positions List'
        }
    },
    {
        path: '/positions-list/:id',
        name: 'ViewPositions',
        component: () => import('./../views/Positions/Edit.vue'),
        meta: {
            auth: true,
            title: 'Positions Edit'
        }
    },
    {
        path: '/profile_attachments-list',
        name: 'ListProfile_attachments',
        component: () => import('./../views/Profile_attachments/List.vue'),
        meta: {
            auth: true,
            title: 'Profile_attachments List'
        }
    },
    {
        path: '/profile_attachments-list/:id',
        name: 'ViewProfile_attachments',
        component: () => import('./../views/Profile_attachments/Edit.vue'),
        meta: {
            auth: true,
            title: 'Profile_attachments Edit'
        }
    },
    {
        path: '/users-list',
        name: 'ListUsers',
        component: () => import('./../views/Users/List.vue'),
        meta: {
            auth: true,
            title: 'Users List'
        }
    },
    {
        path: '/users-list/:id',
        name: 'ViewUsers',
        component: () => import('./../views/Users/Edit.vue'),
        meta: {
            auth: true,
            title: 'Users Edit'
        }
    },
    {
        path: '/working_hours-list',
        name: 'ListWorking_hours',
        component: () => import('./../views/Working_hours/List.vue'),
        meta: {
            auth: true,
            title: 'Working_hours List'
        }
    },
    {
        path: '/working_hours-list/:id',
        name: 'ViewWorking_hours',
        component: () => import('./../views/Working_hours/Edit.vue'),
        meta: {
            auth: true,
            title: 'Working_hours Edit'
        }
    },
    {
        path: '/my_account',
        name: 'MyAccount',
        component: () => import('./../views/MyAccount.vue'),
        meta: {
            auth: true,
            title: 'My Account'
        }
    },
    {
        path: '/salary_report-list',
        name: 'ListSalary_report',
        component: () => import('./../views/Salary_report/List.vue'),
        meta: {
            auth: true,
            title: 'Salary_report List'
        }
    },
    {
        path: '/salary_report-list/:id',
        name: 'ViewSalary_report',
        component: () => import('./../views/Salary_report/Edit.vue'),
        meta: {
            auth: true,
            title: 'Salary_report Edit'
        }
    },
    {
        path: '/report_employee',
        name: 'ReportEmployee',
        component: () => import('./../views/Reports/Home_Report.vue'),
        meta: {
            auth: true,
            title: 'Report Employee'
        },
    },
    {
        path: '/incurrences_take_outs-list',
        name: 'ListIncurrences_take_outs',
        component: () => import('./../views/Incurrences_take_outs/List.vue'),
        meta: {
            auth: true,
            title: 'Incurrences_take_outs List'
        }
    },
    {
        path: '/incurrences_take_outs-list/:id',
        name: 'ViewIncurrences_take_outs',
        component: () => import('./../views/Incurrences_take_outs/Edit.vue'),
        meta: {
            auth: true,
            title: 'Incurrences_take_outs Edit'
        }
    },
   
    
]
const router = new VueRouter({
    routes,
    mode: 'history'
})

export default router
